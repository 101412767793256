<template>
  <div
    class="w-full h-full pt-5"
  >
    <div class="flex ">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
        Approval
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <div>
      <div class="mx-3 mt-6">
        <Card class="mt-6 p-5">
          <div class="flex">
            <StatCard
              class="pb-4"
              label="Total Requests"
              :figure="total"
              icon-card-bg="rgba(50, 28, 59, 0.08)"
              color="rgba(50, 28, 59, 1)"
              icon-name="shield"
            />
            <StatCard
              class="pb-4"
              label="Approved Requests"
              :figure="approved"
              icon-card-bg="rgba(19, 181, 106, 0.08)"
              color="rgba(19, 181, 106, 1)"
              icon-name="shield-approved"
            />
            <StatCard
              class="pb-4"
              label="Open Requests"
              :figure="open"
              icon-card-bg="rgba(233, 147, 35, 0.08)"
              color="rgba(233, 147, 35, 1)"
              icon-name="shield-open"
            />
            <StatCard
              class="pb-4"
              label="Dissapproved Requests"
              :figure="disapproved"
              icon-card-bg="rgba(234, 60, 83, 0.08)"
              color="rgba(234, 60, 83, 1)"
              icon-name="shield-open"
            />
          </div>
        </Card>
      </div>
    <div
      class="w-full h-auto flex flex-col"
      style="align-items: center"
    >
      <div
        class="w-5/12 h-auto mt-5 mb-5 flex"
        style="justify-content: center"
      >
        <icon
          icon-name="illustration_approval"
          class-name="icon-Size mb-4 mt-4 text-white"
        />
      </div>
      <div
        class="w-6/12 h-auto text-center"
        style="width: 610px; line-height:19px"
      >
      <p class="font-semibold text-base text-flame">
          Current approvals in progress shows up here
      </p>
        <p class="mt-5 mb-5 tracking-wide">
            You can track approval progress here until completion,
            after which they will be moved to history.
            Approval flows are complete when approver dissaproves or all approvers approve the action/form.
        </p>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@scelloo/cloudenly-ui/src/components/bread-crumb';
import Card from '@/components/Card';
import StatCard from '@/components/StatCard';
import Icon from "@/components/Icon";

export default {
  name: "EmptyApprovals",
  components: {
    Icon,
    Breadcrumb,
    Card,
    StatCard,
  },
  props: {
    total: {
      type: String,
      default: "0"
    },
    approved: {
      type: String,
      default: "0"
    },
    disapproved: {
      type: String,
      default: "0"
    },
    open: {
      type: String,
      default: "0"
    },
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false, text: "Approval", href: "Approval", id: 'Approval',
        },
        {
          disabled: false, text: "My Approvals", href: "Approvals", id: 'Approvals',
        },
      ],
    };
  },
};
</script>

<style scoped>
.icon-Size {
  width: 200px;
  height: 200px;
}
</style>

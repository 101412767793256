<template>
  <div class="w-full h-full">
    <div class="flex ">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
        Approval
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>
    <div style="height:100%" v-if="loadingData">
      <loader size="xxs" />
    </div>
    <div v-else>
      <div class="mx-3 mt-6 mb-5">
        <Card class="mt-6 p-5">
          <div class="flex">
            <StatCard
              class="pb-4"
              label="Total Requests"
              :figure="total"
              icon-card-bg="rgba(50, 28, 59, 0.08)"
              color="rgba(50, 28, 59, 1)"
              icon-name="shield"
            />
            <StatCard
              class="pb-4"
              label="Approved Requests"
              :figure="approved"
              icon-card-bg="rgba(19, 181, 106, 0.08)"
              color="rgba(19, 181, 106, 1)"
              icon-name="shield-approved"
            />
            <StatCard
              class="pb-4"
              label="Open Requests"
              :figure="open"
              icon-card-bg="rgba(233, 147, 35, 0.08)"
              color="rgba(233, 147, 35, 1)"
              icon-name="shield-open"
            />
            <StatCard
              class="pb-4"
              label="Dissapproved Requests"
              :figure="disapproved"
              icon-card-bg="rgba(234, 60, 83, 0.08)"
              color="rgba(234, 60, 83, 1)"
              icon-name="shield-open"
            />
          </div>
          <template v-slot:footer>
            <CardFooter class="mb-2 mt-2" />
          </template>
        </Card>
      </div>
      <div class="w-full h-full p-3">
        <Tabs
          class="mt-2 mx-2"
          :tabs="tabsItems"
          :active-tab="activeTab"
          @currentTab="activeTab = $event"
          :border="true"
        />
        <div class="w-full my-3">
          <CardFooter reloadcard printcard @reload="reload" />
        </div>
        <div class="w-full h-full">
          <ActiveRequests
            v-if="activeTab === 'Active Requests'"
          />
          <History v-if="activeTab === 'History'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Card from "@/components/Card";
import StatCard from "@/components/StatCard";
import CardFooter from "@/components/CardFooter";
import ActiveRequests from "./ActiveRequests";
import History from "./History";

export default {
  name: "ViewApprovals",
  components: {
    Tabs,
    ActiveRequests,
    History,
    Breadcrumb,
    Card,
    CardFooter,
    StatCard
  },
  props: {
    total: {
      type: String,
      default: "0"
    },
    approved: {
      type: String,
      default: "0"
    },
    disapproved: {
      type: String,
      default: "0"
    },
    open: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      activeTab: "Active Requests",
      radioValue: "ShowAll",
      tab: "ShowAll",
      menuBlock: false,
      tabsItems: ["Active Requests", "History"],
      breadcrumbs: [
        {
          disabled: false,
          text: "Approval",
          href: "Approval",
          id: "Approval"
        },
        {
          disabled: false,
          text: "My Approvals",
          href: "Approvals",
          id: "Approvals"
        }
      ],
      optionBox: [
        { name: "Show All", radioName: "approvaltab", value: "ShowAll" },
        { name: "In-Progress", radioName: "approvaltab", value: "In-Progress" },
        { name: "My Queue", radioName: "approvaltab", value: "MyQueue" },
        { name: "Pending", radioName: "approvaltab", value: "Pending" }
      ]
    };
  },
  watch: {
    radioValue(value) {
      this.tab = value;
      this.menuBlock = !this.menuBlock;
    }
  },
  methods: {
    displayMenuBlock() {
      this.menuBlock = !this.menuBlock;
    }
  }
};
</script>

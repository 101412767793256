<template>
  <div
    class="w-full h-full pt-2"
  >
  <div class="w-full flex flex-col"
  >
  <!-- <ShowAll v-if="option === 'ShowAll'" /> --->
  <InProgress />
  <!-- <MyQueue v-if="option === 'MyQueue'" /> -->
  <!-- <Pending v-if="option === 'Pending'" /> -->
  </div>
  </div>
</template>

<script>
import InProgress from './InProgress';

export default {
    name: 'ActiveRequests',
    components: {
        InProgress,
    },
  props: {
    option: {
      type: String,
      default: 'In-Progress',
    },
  },
}
</script>

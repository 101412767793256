<template>
  <div class="w-full h-full pt-5">
    <div class="w-full flex flex-col">
      <Table
        :headers="requestHeader"
        :items="requestData"
        class="w-full mt-4 mb-4"
        :loading="loading"
        :pagination-list="metaData"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        page-sync
      >
        <template v-slot:item="{ item }">
          <div v-if="item.initiatorId" style="width: 230px" class="pt-3 pb-3">
            <div class="flex">
              <img
                v-if="item.data.photo != null && item.data.photo != ''"
                class="mr-2"
                style="width: 30px; height: 30px"
                :src="item.data.photo"
              />
              <div
                style="height: 35px; width: 35px; border-radius: 5px"
                class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                v-else
              >
                {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
              </div>
              <div class="w-auto flex flex-col">
                <p class="text-sm font-semibold text-darkPurple">
                  {{ `${item.data.fname} ${item.data.lname}` }}
                </p>
              </div>
            </div>
          </div>
          <div v-else-if="item.dateSubmitted">
            <p>
              {{
                $DATEFORMAT(new Date(item.data.dateSubmitted), "MMMM dd, yyyy")
              }}
            </p>
          </div>
          <div v-else-if="item.requestName">
            <span>
              {{ item.data.requestName }}
            </span>
          </div>
          <div v-else-if="item.approvalProgress">
            <div class="flex flex-col">
              <span class="flex">
                <p class="font-bold text-darkPurple text-xl">
                  {{ Math.ceil(item.data.approvalProgress) }}%
                </p>
                <p class="text-romanSilver ml-1 mt-1 text-sm">Completed</p>
              </span>
              <span class="flex">
                <ProgressBar :value="Math.ceil(item.data.approvalProgress)" />
              </span>
            </div>
          </div>
          <div v-else-if="item.status" class="ml-2">
            <Badge
              :label="
                item.data.status === 'Approved' ? 'Approved' : 'Disapproved'
              "
              variant="primary"
              :background-color="
                item.data.status === 'Approved'
                  ? 'rgba(19, 181, 106, 0.08)'
                  : 'rgba(241, 90, 41, 0.15)'
              "
              :color="item.data.status === 'Approved' ? '#13B56A' : '#E99323'"
              style="
                padding: 10px;
                min-height: 32px;
                font-size: 14px;
                font-weight: 600;
              "
            />
          </div>
          <div
            v-else-if="item.requestId"
            class="ml-2 text-blueCrayola cursor-pointer"
            @click="$refs.approval_status.toggle(item.data.requestId)"
          >
            <Icon icon-name="icon-eye" size="xs" class="mt-1" />
          </div>
        </template>
      </Table>
    </div>

      <ApprovalProgress ref="approval_status" />
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "History",
  components: {
    Table,
    Badge,
    ProgressBar,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
  },
  data() {
    return {
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      progressModal: false,
      approvalStatus: "",
      loading: true,
      loadingSide: true,
      requestHeader: [
        { title: "Initiator", value: "initiatorId" },
        { title: "Date Submitted", value: "dateSubmitted", filter: true },
        { title: "Request", value: "requestName" },
        { title: "Approval Progress", value: "approvalProgress" },
        { title: "Status", value: "status" },
        { title: "", value: "requestId", image: true },
      ],
      requestData: [],
      requestProgress: [],
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getHistory(`?${pageNumber}${itemPage}`);
    },

    getHistory(query) {
      const userId = this.$AuthUser.id;
      this.$_getApprovalHistory(userId,query,'&platform=ess')
        .then((result) => {
          this.loading = false;
          const inProgressData = result.data.approvals;
          this.metaData = result.data.meta;
          this.requestData = inProgressData.map((v) => ({
            id: v.id,
            photo: v.photo,
            fname: v.fname,
            lname: v.lname,
            initiatorId: v.initiatorId,
            requestId: v.requestId,
            approvalProgress: v.approvalProgress,
            dateSubmitted: v.dateSubmitted,
            requestName: v.requestName,
            status: v.status,
            requestSlug: v.requests.requestSlug
          }));
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    displayProgress(requestId, status) {
      this.progressModal = true;
      this.loadingSide = true;
      this.$_getApprovalProgress(requestId)
        .then((result) => {
          this.loadingSide = false;
          this.requestProgress = result.data.data;
          this.approvalStatus = status;
        })
        .catch((err) => {
          throw new Error(err);
        });
    },

    approveDetails(value) {
      if (value === "Approved") {
        return "border-color: #13B56A;";
      }
      if (value !== "Approved") {
        return "border-color: rgba(234, 60, 83, 1)";
      }
      return "";
    },
    approveText(value) {
      if (value === "Approved") {
        return "Approved";
      }
      if (value !== "Approved") {
        return "Disapproved";
      }
      return "";
    },
    approveBgColor(value) {
      if (value === "Approved") {
        return "text-mediumSeaGreen bg-light-sea-green";
      }
      if (value !== "Approved") {
        return "text-desire bg-light-flame-red";
      }
      return "";
    },
  },
  mounted() {
    this.getHistory("?page=1&perPage=50");
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<template>
  <div
    class="w-full h-full pt-5"
  >
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
      <div v-else class="w-full h-full">
        <ViewApprovals
        :total="totalRequest"
        :approved="approvedRequest"
        :disapproved="disapprovedRequest"
        :open="openRequest"
        v-if="totalRequest > 0"
        />
        <EmptyApprovals
        :total="totalRequest"
        :approved="approvedRequest"
        :disapproved="disapprovedRequest"
        :open="openRequest"
        v-else
        />
      </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import EmptyApprovals from './EmptyApprovals';
import ViewApprovals from './viewapprovals/ViewApprovals';

export default {
  name: 'Approvals',
  components: {
    EmptyApprovals,
    ViewApprovals,
    Loader,
  },
  data() {
    return {
      hasApprovals: true,
      loading: true,
      openModal: false,
      totalRequest: '',
      approvedRequest: '',
      disapprovedRequest: '',
      openRequest: '',
    };
  },
  methods: {
    getApprovalNumber() {
      const userId = this.$AuthUser.id;
      const params = "myapprovals";
      this.$_getApprovalNumbers(params, userId)
        .then(result => {
          this.loading = false;
          this.totalRequest = result.data.TotalRequest.toString();
          this.approvedRequest = result.data.ApprovedRequest.toString();
          this.disapprovedRequest = result.data.DisapprovedRequests.toString();
          this.openRequest = result.data.OpenRequest.toString();
        }).catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getApprovalNumber();
  },
};
</script>


<template>
  <div class="w-full h-full pt-1">
    <div class="w-full flex flex-col">
      <Table
        :headers="requestHeader"
        :items="requestData"
        class="w-full mt-4 mb-4"
        :loading="loading"
        :pagination-list="metaData"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        page-sync
        v-if="requestData.length > 0 || loading"
      >
        <template v-slot:item="{ item }">
          <div v-if="item.initiatorId" style="width: 230px" class="pt-3 pb-3">
            <div class="flex">
              <img
                v-if="item.data.photo != null && item.data.photo != ''"
                class="mr-2"
                style="width: 30px; height: 30px"
                :src="item.data.photo"
              />
              <div
                style="height: 30px; width: 30px; border-radius: 5px;"
                class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                v-else
              >
                {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
              </div>
              <div class="w-auto flex flex-col">
                <p class="text-sm font-semibold text-darkPurple">
                  {{ `${item.data.fname} ${item.data.lname}` }}
                </p>
              </div>
            </div>
          </div>
          <div v-else-if="item.createdAt">
            <p>
              {{ $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy") }}
            </p>
          </div>
          <div v-else-if="item.requestName">
            <span
              class="cursor-pointer underline"
              @click="
                viewDetailedApproval(
                  item.data.requestId,
                  item.data.requestSlug
                )
               "
            >
              {{ item.data.requestName }}
            </span>
          </div>
          <div v-else-if="item.approvalPercentage">
            <div class="flex flex-col">
              <span class="flex">
                <p class="font-bold text-darkPurple text-xl">
                  {{ Math.ceil(item.data.approvalPercentage) }}%
                </p>
                <p class="text-romanSilver ml-1 mt-1 text-sm">
                  Completed
                </p>
              </span>
              <span class="flex">
                <ProgressBar :value="Math.ceil(item.data.approvalPercentage)" />
              </span>
              <p class="font-semibold text-xs text-romanSilver uppercase">
                {{ item.data.approversLeft }} approvers left
              </p>
            </div>
          </div>
          <div
            v-else-if="item.id"
          >
                <Badge
                  label="In Progress"
                  variant="primary"
                  background-color="rgba(233, 147, 35, 0.08)"
                  color="#E99323"
                  style="padding: 10px; min-height: 32px; font-size: 14px; font-weight: 600"
                />
          </div>
          <div
            v-else-if="item.requestId"
            @click="$refs.approval_status.toggle(item.data.requestId)"
            class="text-blueCrayola cursor-pointer"
          >
          <Icon icon-name="icon-eye" size="xs" class="mt-1" />
          </div>
        </template>
      </Table>

      <div
        class="w-full flex flex-col justify-center items-center mt-10"
        v-else
      >
        <icon icon-name="empty_agreement" size="l" style="width: 300px" />
        <div class="w-1/2 text-base text-center">
          You have no active approval requests
        </div>
      </div>
    </div>

    <ApprovalProgress ref="approval_status" />
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "InProgress",
  components: {
    Table,
    Badge,
    ProgressBar,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
  },
  data() {
    return {
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      progressModal: false,
      disabled: true,
      loading: true,
      loadingSide: true,
      requestProgress: [],
      requestHeader: [
        { title: "Initiator", value: "initiatorId" },
        { title: "Date Submitted", value: "createdAt", filter: true },
        { title: "Request", value: "requestName" },
        { title: "Approval Progress", value: "approvalPercentage" },
        { title: "Status", value: "id" },
        { title: "", value: "requestId" }
      ],
      requestData: []
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getInProgress(`${pageNumber}${itemPage}`);
    },

    getInProgress(query) {
      const userId = this.$AuthUser.id;
      const params = "";
      this.$_getMyApprovalsfilter(params, userId, query, '&platform=ess')
        .then(result => {
          this.loading = false;
          const inProgressData = result.data.approvals;
          this.metaData = result.data.meta;
          this.requestData = inProgressData.map(v => ({
            id: v.id,
            photo: v.photo,
            fname: v.fname,
            lname: v.lname,
            initiatorId: v.initiatorId,
            requestId: v.requestId,
            approvalPercentage: v.requests.approvalPercentage,
            createdAt: v.requests.createdAt,
            approversLeft: v.requests.approversLeft,
            requestName: v.requestName,
            requestSlug: v.requests.requestSlug
          }));
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    displayProgress(requestId) {
      this.progressModal = true;
      this.loadingSide = true;
      this.$_getApprovalProgress(requestId)
        .then(result => {
          this.loadingSide = false;
          this.requestProgress = result.data.data;
        })
        .catch(err => {
          throw new Error(err);
        });
    },
    viewDetailedApproval(requestId, requestSlug) {
      if (requestSlug === 'approvePayRun') {
        this.$router.push({
          name: `Ess${requestSlug}`,
          params: { id: `${requestId}` },
          query: {slug: 'approval'}
        });
      }
      else {
        this.$router.push({
          name: `Ess${requestSlug}`,
          params: { id: `${requestId}` }
        });
      }
    },

    approveDetails(value) {
      if (value === "hasApproved") {
        return "border-color: #13B56A;";
      }
      if (value === "active") {
        return "border-color: #E99323;";
      }
      if (value === "inactive") {
        return "border-color: #333333;";
      }
      return "";
    },
    approveText(value) {
      if (value === "hasApproved") {
        return "Approved";
      }
      if (value === "active") {
        return "Pending";
      }
      if (value === "inactive") {
        return "Not Started";
      }
      return "";
    },
    approveBgColor(value) {
      if (value === "hasApproved") {
        return "text-mediumSeaGreen bg-light-sea-green";
      }
      if (value === "active") {
        return "text-carrotOrange bgCarrot";
      }
      if (value === "inactive") {
        return "text-black bg-gray-200";
      }
      return "";
    }

  },
  mounted() {
    this.getInProgress("&page=1&perPage=50");
  }
};
</script>

<style scoped>
.bgCarrot {
  background-color: rgba(233, 147, 35, 0.08);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
